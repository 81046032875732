<template>
  <apexchart
    :height="400"
    :width="width"
    type="line"
    :options="chartOptions"
    :series="series"
  ></apexchart>

</template>
<script>
export default {
  name: "BarChart",

  props: ['width'],

  setup() {
    let series = [{
      name: 'Net Profit',
      type: 'column',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    },{
      name: 'Net Profit',
      type: 'column',
      data: [49, 65, 67, 66, 70, 68, 63, 60, 66]
    },{
      name: 'Free Cash Flow',
      type: 'line',
      data: [23, 36, 22, 47, 20, 37, 44, 33, 41]
    }];

    let chartOptions = {
      chart: {
        height: 400,
        type: 'line',
        stacked: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [0, 0, 3]
      },
      xaxis: {
        categories: ['All Products', 'F/X', 'Cash Mgmt', 'T/F', 'Funding', 'Corp. Banking', 'Leasing', 'Working Capital (Factoring)', 'CAD', 'Corp. Cards', 'ESG'],
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#435BF4'
          },
          labels: {
            style: {
              colors: '#435BF4',
            }
          },
          title: {
            text: "Income (thousand crores)",
            style: {
              color: '#435BF4',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        {
          seriesName: 'Income',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#435BF4'
          },
          labels: {
            style: {
              colors: '#435BF4',
            }
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 80,
          offsetX: 40
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    }

    return {
      chartOptions,
      series
    };
  }
};
</script>
<style lang="scss">
  .apexcharts-svg{
    .apexcharts-inner{
      .apexcharts-bar-series{
       .apexcharts-series{
         &:first-child{
           path{
             fill: #435BF4;
           }
         }
         &:last-child{
           path{
             fill: #CED4F8;
           }
         }
       }
      }
    }
    .apexcharts-line-series{
      .apexcharts-series{
        path{
          stroke: #55B035;
        }
      }
    }
  }
</style>