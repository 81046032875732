<template>
  <el-dialog
    v-model="popupVisible"
    width="500px"
    center
    :show-close="true"
    append-to-body
    custom-class="confirmation-modal"
    @close="closePopup"
  >
    <div class="text-center">
      <h2>{{$t(title)}}</h2>
      <p class="text-gray-500 fs-6 my-10">{{$t(description)}}</p>
      <div class="d-flex border-top mt-5 justify-content-center pt-8">
        <el-button
          class="btn btn-outline-secondary text-gray-600"
          @click="closePopup"
        >
          {{ $t('Cancel') }}
        </el-button>
        <el-button
          class="btn border btn-outline-danger border-danger rounded-pill px-15"
          @click="confirmPopup"
        >
          {{ $t(btnName) }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>

  export default {
    name: "ConfirmationModal",

    props: ["popupVisible", "title", 'description', 'btnName'],

    emits: ["handle-close", "handle-confirm"],

    data() {
      return {
        dialogFormVisible: false,
      };
    },

    created() {

    },
    methods: {
      closePopup() {
        this.$emit("handle-close");
      },
      confirmPopup() {
        this.$emit("handle-confirm");
        this.$emit("handle-close");
      },
    }
  };
</script>
<style lang="scss">
  .textareaQuestion{
    .el-textarea__inner{
      min-height: 33px;
      background: #F8F8F8;
      border-radius: 10px;
      padding: 20px;
      border: none;
    }
  }
  .confirmation-modal {
    @media (max-width: 520px) {
      --el-dialog-width: 98% !important;
    }
  }
</style>

<!--Example component -->
<!--  <CustomDelete-->
<!--    :title="'Are you shure you want to delete'"-->
<!--    :description="'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aspernatur assumenda deleniti dignissimos dolorem'"-->
<!--    :popupVisible="dialogDeleteCommentVisible"-->
<!--    @handle-close="deleteModalVisible = false"-->
<!--    @handle-confirm="handleDelete"-->
<!--  />-->
