<template>
    <div class="productArea-slider">
        <template v-if="!isLoading && constructProductAreaData.length">
            <el-carousel
                class="mt-10 p-0 productArea"
                :autoplay="false"
                arrow="always"
                indicator-position="outside">
                <el-carousel-item v-for="item in constructProductAreaData" :key="item">
                    <div class="d-flex px-10 pb-10">
                        <div class="chart-item" :class="'chart-item__index-' + index" v-for="(bank, index) in item"
                             :key="index">
                            <p class="fs-4 fw-bolder">{{ bank.product_area }}</p>
                            <hr class="bg-dark w-50px">
                            <div class="d-flex overflow-scroll">
                                <CircleChart
                                    v-for="(chart, chartIdx) in bank.bank_data"
                                    :key="chartIdx"
                                    :labelBottom="chart.titleBottom"
                                    :chartData="chart"
                                    :chartConfigs="{
                                        width: 160,
                                        height: 0.8 * 160,
                                        valueVisible: false
                                    }"
                                    :values="[chart.score, chart.importance]"
                                />
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div
                class="w-100 bg-light-primary p-7 d-flex justify-content-between rounded-bottom position-absolute left-0 bottom-0 w-100"
                :class="{'h-60px': !profileSetting}"
            >
                <div></div>
                <router-link
                    v-if="profileSetting"
                    to="/business/settings/user-settings/settings"
                    class="fs-4 fw-bolder text-primary">
                    {{ $t("Add Product Areas") }}
                </router-link>
            </div>
        </template>
        <div v-else class="mb-20 px-10">
            <EmptyStateProductArea
                :title="$t(getEmptyStateTexts.title)"
                :description="$t(getEmptyStateTexts.description)"
            />
        </div>
    </div>
</template>
<script>
import CircleChartModel from "@/store/models/CircleChartModel";
import EmptyStateProductArea from "@/buying-teams/shared-components/utils/EmptyStateProductArea";
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import { APP_PAGES } from "@/core/config/constants";

export default {
    name: "ProductAreas",
    components: {
        CircleChart,
        EmptyStateProductArea
    },
    props: {
        profileInfo: Object,
        page: String
    },
    watch: {
        profileInfo: {
            handler(val) {
                this.isLoading = true;
                if (val) {
                    setTimeout(() => {
                        this.isLoading = false;
                    });
                }
            },
            deep: true
        }
    },
    data() {
        return {
            profileSetting: false,
            isLoading: false
        };
    },
    mounted() {
        let id = this.$route.params.id;

        if (!id) {
            this.profileSetting = true;
        }
    },
    methods: {
        chunkArray(arr, n) {
            var chunkLength = Math.max(arr.length / n, 1);
            var chunks = [];
            for (var i = 0; i < n; i++) {
                if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
            }
            return chunks;
        }
    },
    computed: {
        constructProductAreaData() {
            let product_area_data = [];

            if (this.profileInfo.product_area_data) {
                for (let [productAreaName, banksData] of Object.entries(this.profileInfo.product_area_data)) {
                    let prDt = {
                        product_area: productAreaName,
                        bank_data: []
                    };

                    if (banksData) {
                        for (let [index, [bankName, bankData]] of Object.entries(Object.entries(banksData))) {
                            // if (Number(index) < 6) {
                            delete bankData.product_area;
                            bankData.bank_name = bankName;
                            prDt.bank_data.push(new CircleChartModel({ ...bankData }));
                            // }
                        }
                    }

                    product_area_data.push(prDt);
                }
            }

            return this.chunkArray(product_area_data, Math.round(product_area_data.length / 2)).reverse();
        },
        getEmptyStateTexts() {
            let texts = {
                title: '',
                description: '',
            };

            if (this.page === APP_PAGES.BUSINESS.SINGLE_USER) {
                texts.title = 'No product areas';
                texts.description = "The user don’t have any product areas with data yet";
            } else if (this.page === APP_PAGES.BUSINESS.PROFILE_OVERVIEW) {
                texts.title = 'Add Product Area';
                texts.description = 'Use the button below to add your first product area and leverage real-time ratings on them.';
            }

            return texts;
        }
    }
};
</script>
<style lang="scss">
.productArea {
    .el-carousel__arrow {
        top: inherit;
        bottom: 0;
        background: transparent;
        color: #435BF4;
        font-size: 20px;
    }

    .el-carousel__arrow--right {
        left: 13%;
    }

    .el-carousel__indicators--horizontal {
        left: 6%;
        position: absolute;
        bottom: 20px;
    }

    .el-carousel__indicator {
        &.is-active {
            .el-carousel__button {
                background: #435BF4;
            }
        }

        .el-carousel__button {
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
    }

    .chart-item {
        width: 50%;

        &__index-0 {
            padding-right: 40px;
            border-right: 1px solid #EFF2F5;
        }

        &__index-1 {
            padding-left: 40px;
        }
    }
}

@media (max-width: 768px) {
    .productArea-slider {
        .el-carousel__container {
            height: 557px;
        }

        .el-carousel__item {
            > .d-flex {
                flex-direction: column;

                .chart-item {
                    width: 100%;
                    padding: 0;
                }

                .chart-item__index-0 {
                    border-right: none;
                    border-bottom: 1px solid #EFF2F5;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
</style>
