<template>
  <el-table
    :data="banksData"
    :show-header="false"
    class="business-banks-table"
    style="width: 100%">
    <el-table-column
      prop="date"
      label=""
      width="150"
      v-slot="icon_path">
      <div class="text-center">
        <img :src="icon_path.row.icon_path" class="bank-icon-container" width="31" height="31" alt="">
      </div>
    </el-table-column>
    <el-table-column
      prop="name"
      label=""
      width="350"
      v-slot="bank">
      <p class="fw-boldest fs-3">{{ bank.row.name ? bank.row.name : bank.row.bank_name }}</p>
    </el-table-column>
    <el-table-column
      prop="address"
      v-slot="countries">
      <span class="fs-3" v-for="(country, i) in countries.row.countries">{{ i < countries.row.countries.length - 1 ? country + ', ' : country }} </span>
    </el-table-column>
    <el-table-column
      v-if="showAction"
      v-slot="actions"
      width="70"
    >
      <div class="me-0">
        <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <i class="bi bi-three-dots fs-1 fw-bolder"></i>
        </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item class="w-200px" command="a" @click="$emit('handle-edit', actions.row)">
                {{$t(("Edit")) }}
              </el-dropdown-item>
              <el-dropdown-item class="w-200px" command="b" @click="$emit('handle-delete', actions.row)">
                {{ $t(("Remove From List")) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-table-column>
  </el-table>
</template>

<script>

  export default {
    name: "BusinessBanksTable",
    components: {},
    props: ["banksData", "showAction"],
    emits: ["handle-edit", "handle-delete"],
    data() {
      return {};
    }
  };
</script>
<style lang="scss">
.business-banks-table {
  @media (max-width: 768px) {
    &.el-table::before {
      display: none;
    }
    .el-table__header-wrapper {
      display: none;
    }
    .el-table__body {
      display: flex;
      width: 100% !important;
      colgroup {
        display: none;
      }
      .el-table__row {
        display: flex;
        flex-wrap: wrap;
        &:not(:last-child) {
          border-bottom: 1px solid #EFF2F5;
          padding-bottom: 15px;
        }
        .el-table__cell {
          border: none !important;
          &:nth-child(1) {
            width: 70px;
          }
          &:nth-child(2) {
            width: calc(100% - 70px);
            display: flex;
            align-items: center;
          }
          &:nth-child(3) {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            .cell {
              display: flex;
              flex-wrap: wrap;
              &.el-tooltip {
                width: auto !important;
                display: flex;
                flex-wrap: wrap;
              }
              p {
                margin-bottom: 0 !important;
              }
              span {
                margin-right: 5px;
              }
            }
          }
          &:nth-child(4) {
            position: absolute;
            background: transparent;
            right: 0;
          }
        }
      }
    }
  }
}
</style>
