<template>
    <div class="card-body p-9 px-0 profile-details" v-if="profileDetails">
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Full Name") }}</label>
            <div class="col-lg-8">
                <span class="profile-details__text">{{ profileDetails.getFullName() }}</span>
            </div>
        </div>
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Company") }}</label>
            <div class="col-lg-8 fv-row">
                <span class="profile-details__text">{{ businessData.name }}</span>
            </div>
        </div>
        <div class="row mb-8">
            <label class="col-lg-4 profile-details__label">{{ $t("Email Address") }}</label>
            <div class="col-lg-8 d-flex align-items-center">
                <span class="profile-details__text">{{ profileDetails.email }}</span>
                <!--        <span class="px-5 py-1 text-white bg-success border rounded-3">{{$t('Verified')}}</span>-->
            </div>
        </div>
        <div class="row">
            <label class="col-lg-4 profile-details__label">{{ $t("Role") }}</label>

            <div class="col-lg-8">
                <span class="profile-details__text">{{ $t("role." + profileDetails.role) }}</span>

            </div>
        </div>
        <div class="row mt-8" v-if="profileDetails.role === UserRoleEnum.USER">
            <label class="col-lg-4 profile-details__label">{{ $t("Permission Group") }}</label>
            <div class="col-lg-8 fv-row">
                <span class="profile-details__text text-capitalize">
                    {{ profileDetails.getPermissionGroupName || $t("No Permission Group") }}
                </span>
            </div>
        </div>
        <!--    <div class="row">-->
        <!--      <label class="col-lg-4 profile-details__label">{{ $t('Preferred Language') }}</label>-->

        <!--      <div class="col-lg-8">-->
        <!--        <div class="d-flex align-items-center justify-content-start">-->
        <!--          <img :src="profileDetailsLanguage.icon_path" class="me-2" width="20" height="20" alt="">-->
        <!--          <span class="profile-details__text">{{ profileDetailsLanguage.title }}</span>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
    </div>
</template>
<script>
import { SUPPORTED_LANGUAGES } from "@/core/config/constants";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";

export default {
    name: "ProfileDetails",

    props: ["profileDetails", "businessData"],

    data() {
        return {
            supportedLanguages: SUPPORTED_LANGUAGES,
            UserRoleEnum
        };
    },
    computed: {
        profileDetailsLanguage() {
            return {
                icon_path: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].icon_path : "/media/buying/icons/languages/globe-earth.svg",
                title: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].title : this.$t("No selected")
            };
        }
    }
};
</script>
<style lang="scss" scoped>
.profile-details {
    &__label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8B8B8B;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #040404;
    }
}
</style>
