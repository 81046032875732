<template>
    <el-drawer @closed="closeModal" size="200" v-model="showForm" :with-header="false">
        <div class="card shadow-none rounded-0 w-100">
            <div class=" d-flex align-items-center" id="kt_bank_header">
                <button
                    type="button"
                    class="btn btn-sm btn-icon explore-btn-dismiss"
                    id="kt_bank_close"
                    @click="closeModal"
                >
            <span class="">
              <inline-svg src="/media/buying/icons/general/arr061.svg" />
            </span>
                </button>
                <h5 class="mb-0 fs-2 fw-bold">{{ isEdit ? $t("Edit Bank") : $t("Add New Bank") }}</h5>
            </div>
            <div class="card-body filter-body mt-20" id="kt_help_body">
                <el-form
                    ref="createBankFormRef"
                    :rules="createBankFormRules"
                    :model="createBankForm"
                >
                    <el-form-item prop="bank_name">
                        <label>{{ $t("Bank Name") }}</label>
                        <el-select
                            :disabled="isEdit"
                            size="large"
                            v-model="createBankForm.bank_name"
                            filterable
                            placeholder="Select"
                        >
                            <el-option
                                v-for="(item, index) in allBanks"
                                :key="index"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>

                    </el-form-item>
                    <el-form-item prop="countries" class="filterable-select">
                        <label>{{ $t("Bank Country") }}*</label>
                        <el-select
                            v-model="createBankForm.countries"
                            size="large"
                            filterable
                            multiple
                            default-first-option
                            :reserve-keyword="false"
                            collapse-tags
                            :placeholder="$t('Add More')"
                        >
                            <el-option
                                v-for="item in allCountries"
                                :key="item"
                                :label="item"
                                :value="item"
                            />
                        </el-select>
                    </el-form-item>
                </el-form>
                <button
                    :data-kt-indicator="buttonIndicator" :disabled="buttonIndicator === 'on'"
                    class="btn main-btn w-100 mt-15"
                    @click="saveBanks">
                    <span class="indicator-label"> {{ $t("Save") }}</span>
                    <span class="indicator-progress">
            {{ $t("pleaseWait") }}
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
                </button>
            </div>
        </div>
    </el-drawer>
</template>
<script>
import store from "../../../store";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";

export default {
    name: "AddBanks",

    props: [
        "isAddBanksVisible",
        "dataForEdit",
        "isEditBank",
        "userInfo",
        "isOwn"
    ],
    emits: ["handle-close", "handle-save"],
    data() {
        return {
            buttonIndicator: "off",
            showForm: false,
            isEdit: false,
            createBankForm: {
                bank_name: "",
                countries: []
            },
            createBankFormRules: {
                bank_name: [
                    { required: true, message: this.$t("Please select Bank"), trigger: "change" }
                ],
                countries: [
                    { required: true, message: this.$t("Please select Country"), trigger: "change" }
                ]
            }
        };
    },

    computed: {
        allBanks() {
            return store.getters.allBanks;
        },
        allCountries() {
            return store.getters.allCountries;
        }
    },

    async mounted() {
        this.showForm = this.isAddBanksVisible;
        this.params = {
            business_banks: [],
            product_areas: this.userInfo.product_areas,
            id: this.userInfo.id
        };
        store.dispatch("fetchStaticData", StaticDataEnum.BANKS);
        store.dispatch("fetchStaticData", StaticDataEnum.COUNTRIES);
        if (this.isEditBank) {
            this.editBank(this.dataForEdit);
        }
    },


    methods: {
        editBank(data) {
            this.isEdit = true;
            this.createBankForm.bank_name = data.name;
            this.createBankForm.countries = data.countries;
            this.showForm = true;
        },

        async saveBanks() {
            this.$refs.createBankFormRef.validate(async valid => {
                if (valid) {
                    this.buttonIndicator = "on";
                    this.isEdit = false;
                    let banks = this.userInfo.business_banks;
                    banks = banks.map(item => {
                        return {
                            bank_name: item.name,
                            countries: item.countries
                        };
                    });
                    if (this.isEditBank) {
                        banks.forEach(val => {
                            if (val.bank_name === this.createBankForm.bank_name) {
                                val.countries = this.createBankForm.countries;
                            }
                        });
                        this.params.business_banks = [...banks];
                    } else {
                        this.params.business_banks = [this.createBankForm, ...banks];
                    }
                    if (!this.isOwn) {
                        this.params.is_add_bank = true;
                    }
                    await store.dispatch(!this.isOwn ? "updateUserDetails" : "updateProfileDetails", this.params)
                        .then(res => {
                            if (res) {
                                let bank = this.allBanks.find(val => val.name === this.createBankForm.bank_name);

                                if (bank) bank.countries = this.createBankForm.countries;

                                this.$emit("handle-save", bank);

                                this.showForm = false;
                            }
                        }).finally(() => {
                            this.buttonIndicator = "off";
                        });
                }
            });
        },

        closeModal() {
            this.isEdit = false;
            this.createBankForm = {
                bank_name: "",
                countries: ""
            };
            this.$emit("handle-close", false);
        }
    }
};
</script>
<style lang="scss">
.filterable-select {
    .el-select__tags {
        position: revert;
        transform: revert;

        > span {
            position: absolute;
            bottom: -36px;
        }

        .el-select__input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100% !important;
            z-index: 9;
        }
    }
    .el-input {
        .el-input__inner {
            height: 56px !important;
        }
    }
}
</style>
