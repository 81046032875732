<template>
  <div class="no-product-area-score d-flex align-items-center">
    <img src="/media/misc/productAreaScore.svg" alt="">
    <div class="ms-7">
      <h2>{{ title }}</h2>
      <p class="mb-0">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyStateProductArea",
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.no-product-area-score {
  padding: 43px 0;
  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #212121;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    color: #8B8B8B;
  }
}
</style>
